<template>
  <div class="photo-form">
    <div class="form-wrapper form-style-1">
      <h1 style="font-size: 36px; font-weight: bold; background: linear-gradient(100deg, #1985A1 0%, #024455 100%); -webkit-text-fill-color: transparent; background-clip: text; margin: 0;">My Profile</h1>
      <div class="form">
        <form action="" novalidate>
          <div class="form-group photo-form-group">
            <div class="input-field-wrapper photo-input-field-wrapper">
              <!-- <label for="photo">Photo</label> -->
              <div class="input-field-inner-wrapper">
                <div class="input-field">
                  <input id="photo" type="file" v-on:change="handleImageUpload" />
                </div>
                <div class="photo-preview" ref="photoPreview" v-if="hasPhoto || photoSelected" :style="{ 'background-image': 'url(' + photoUrl + ')' }">
                  <div class="loader"></div>
                  <div class="check-mark"></div>
                </div>
                <label for="photo" class="no-photo-preview" v-if="!(hasPhoto || photoSelected)"></label>
                <!-- <label for="photo" class="additional-label">{{ photoLabel }}</label> -->
              </div>
            </div>

            <div class="form-error" v-if="error">
              <div class="error">{{ error }}</div>
            </div>
          </div>
        </form>
      </div>
      <div class="loading-overlay">
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from '@/axios';

export default {
  name: 'PhotoForm',
  data() {
    return {
      error: null,
      photo: null,
      photoSelected: false,
    };
  },
  computed: {
    hasPhoto() {
      return !!this.$store.state.user.profileImage;
    },
    photoUrl() {
      if (this.photoSelected) {
        return URL.createObjectURL(this.photo);
      } else if (this.hasPhoto) {
        return this.$store.state.user.profileImage;
      } else {
        return '';
      }
    },
    photoLabel() {
      return this.hasPhoto || this.photoSelected ? 'Change' : 'Upload Photo';
    },
  },
  methods: {
    handleImageUpload(e) {
      this.error = null;
      this.photoSelected = true;
      this.photo = e.target.files[0];

      this.$nextTick(() => {
        this.$refs.photoPreview.classList.remove('loaded');
        this.$refs.photoPreview.classList.add('loading');
      });

      let formData = new FormData();
      formData.append('profileImage', this.photo);

      this.$store
        .dispatch('uploadPhoto', formData)
        .then(() => {
          this.$refs.photoPreview.classList.remove('loading');
          this.$refs.photoPreview.classList.add('loaded');

          setTimeout(() => {
            this.$refs.photoPreview.classList.remove('loaded');
          }, 3000);

          window.dispatchEvent(new CustomEvent('addSuccessMessage', {detail: {message: 'Photo Changed'}}));
        })
        .catch((error) => {
          this.photoSelected = false;
          this.$refs.photoPreview.classList.remove('loading');
          this.error = error;
        });
    },
  },
};
</script>

<style lang="scss">
@import '../../scss/partials/variables.scss';
@import '../../../node_modules/tom-select/dist/css/tom-select.css';
@import '../../scss/partials/tom-select-overrides.scss';

.photo-form {
  // margin-bottom: 30px;
  // padding-bottom: 30px;
  // border-bottom: 1px solid #c1c1c1;

  .form {
    padding: 20px 30px 5px;
  }

  @media (max-width: 640px) {
    .form {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.form-style-1 .photo-input-field-wrapper .input-field-inner-wrapper .photo-preview {
  border: 1px solid #1985a1 !important;
}
</style>
