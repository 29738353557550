<template>
  <div class="container xl">
    <div class="main-cols">
      <div class="left-menu-col">
        <LeftMenu />
      </div>
      <div class="right-content-col">
        <div class="settings">
          <PhotoForm />
          <ProfileForm />
          <ChangePasswordForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu';
import PhotoForm from '@/components/profile/PhotoForm';
import ProfileForm from '@/components/profile/ProfileForm';
import ChangePasswordForm from '@/components/profile/ChangePasswordForm';



export default {
  name: 'SettingsView',
  components: {
    LeftMenu,
    PhotoForm,
    ProfileForm,
    ChangePasswordForm
  },
};
</script>
